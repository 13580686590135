import { h } from 'preact';
import { Router } from 'preact-router';

//import Header from './header';
import { Container } from 'reactstrap';
// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import Register from '../routes/register';

const App = () => (
  <Container id="app">
    <Router>
      <Home path="/" />
      <Register path="/viceroy/" brand="viceroy" customisation={require('../versions/viceroy')} mode="prod" />
      <Register path="/viceroy-dev/" brand="viceroy" customisation={require('../versions/viceroy')} mode="dev" />
      <Register path="/cata/" brand="cata" customisation={require('../versions/cata')} mode="prod" />
      <Register path="/cata-dev/" brand="cata" customisation={require('../versions/cata')} mode="dev" />
      <Register path="/karlson/" brand="karlson" customisation={require('../versions/karlson')} mode="prod" />
      <Register path="/karlson-dev/" brand="karlson" customisation={require('../versions/karlson')} mode="dev" />
    </Router>
  </Container>
);

export default App;
